/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

export class MapContainer extends Component {
	render() {
		return (
			<iframe
				title="Google Maps"
				width="100%"
				frameBorder="0"
				className="lazyload bg-gray-700"
				style={{ border: 0, height: '50vh', minHeight: '21.5em' }}
				data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.7155751598016!2d16.359984614989795!3d48.212094153948016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07fc3f480cdd%3A0x47652d750772498b!2sMovendos%20Physiotherapie%20und%20Osteopathie!5e0!3m2!1sde!2sde!4v1591976984364!5m2!1sde!2sde"
				allowFullScreen
			>
				Lade Karte
			</iframe>
		);
	}
}

export default MapContainer;
