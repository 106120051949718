import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

const Hero = () => (
	<StaticQuery
		query={graphql`
			query {
				desktop: file(relativePath: { eq: "physios_21_II.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 4000) {
							...GatsbyImageSharpFluid_tracedSVG
						}
					}
				}
				ff: file(relativePath: { eq: "physios_21_II.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		`}
		render={(data) => {
			// Set ImageData.
			let imageData = data.desktop.childImageSharp.fluid;
			if (typeof window !== 'undefined' && window.navigator.userAgent.indexOf('Firefox') !== -1 && window.navigator.userAgent.indexOf('Mobile') !== -1) {
				imageData = data.ff.childImageSharp.fluid;
			}
			return (
				<BackgroundImage
					fluid={imageData}
					className="hero-main h-screen items-end py-32 px-5 md:-mb-header relative mt-10 bg-bottom"
					style={{ maxHeight: '90vw' }}
				>
				</BackgroundImage>
			);
		}}
	/>
);

export default Hero;
