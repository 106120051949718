import * as React from "react"

function MedizinLogo(props) {
  return (
    <svg
      id="prefix__Ebene_2"
      x={0}
      y={0}
      viewBox="0 0 791 357"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#fff}"}</style>
      <defs>
        <path id="prefix__SVGID_1_" d="M-65.7-36H1016v226.1H-65.7z" />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_2_)">
        <path
          className="prefix__st1"
          d="M132 298v96h-22.6v-23.5c0-22.8.8-34 .7-34h-.1c-.1 0-7.8 13.2-8.6 14.5l-11.2 19.2H78.1L66.9 351c-.8-1.4-8.5-14.5-8.6-14.5h-.1c-.1 0 .7 11.2.7 34V394H36.2v-96h21.7l18 29.1c7 11.4 8.1 13.9 8.2 13.9h.1c.1 0 1.2-2.5 8.2-13.9l18-29.1H132zM224.1 372.5V394h-70.6v-96h69.8v21.5h-46.8v15.8h36.6v20.2h-36.6v17h47.6zM286.3 298c27.4 0 49.7 19.6 49.7 48.4S313.8 394 286.3 394h-42v-96h42zm0 74.5c13.7 0 25.6-9.6 25.6-26.1s-11.9-26.9-25.6-26.9h-18.9v52.9h18.9v.1zM351.5 394v-96h23v96h-23zM474.7 319.6l-40.9 44c-4.3 4.5-8.9 9.1-8.9 9.2l.1.1c.1 0 6.7-.4 14.9-.4h34.7V394h-81.2v-21.5l42.8-45c3.4-3.7 8.9-8.1 8.9-8.2l-.1-.1c-.1 0-8.9.4-15 .4h-35.2V298h79.8v21.6h.1zM493.6 394v-96h23v96h-23zM624.2 298v96h-21.7l-19.6-26.7c-8.8-12.1-21.7-30.3-21.8-30.3h-.1c-.1 0 .1 14.8.1 35.8V394h-23v-96h21.7l20.4 27.4c6.2 8.2 20.8 28.9 21 28.9h.1c.1 0-.1-17-.1-34.4V298h23z"
        />
      </g>
      <defs>
        <path id="prefix__SVGID_3_" d="M-65.7-36H1016v226.1H-65.7z" />
      </defs>
      <clipPath id="prefix__SVGID_4_">
        <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_4_)">
        <path
          className="prefix__st1"
          d="M250.3 454.1v96h-22.6v-23.5c0-22.8.8-34 .7-34h-.1c-.1 0-7.8 13.2-8.6 14.5l-11.2 19.2h-11.9l-11.2-19.2c-.8-1.4-8.5-14.5-8.6-14.5h-.1c-.1 0 .7 11.2.7 34v23.5h-22.6v-96h21.7l18 29.1c7 11.4 8.1 13.9 8.2 13.9h.1c.1 0 1.2-2.5 8.2-13.9l18-29.1h21.3zM342.4 528.5V550h-70.6v-96h69.8v21.5h-46.8v15.8h36.6v20.2h-36.6v17h47.6zM404.6 454.1c27.4 0 49.7 19.6 49.7 48.4s-22.2 47.6-49.7 47.6h-42v-96h42zm0 74.4c13.7 0 25.6-9.6 25.6-26.1s-11.9-26.9-25.6-26.9h-18.9v52.9l18.9.1zM469.8 550.1v-96h23v96h-23zM592.9 475.6l-40.9 44c-4.3 4.5-8.9 9.1-8.9 9.2l.1.1c.1 0 6.7-.4 14.9-.4h34.7V550h-81.2v-21.5l42.8-45c3.4-3.7 8.9-8.1 8.9-8.2l-.1-.1c-.1 0-8.9.4-15 .4H513v-21.5h79.8v21.5h.1zM611.9 550.1v-96h23v96h-23zM742.4 454.1v96h-21.7l-19.6-26.7c-8.8-12.1-21.7-30.3-21.8-30.3h-.1c-.1 0 .1 14.8.1 35.8v21.3h-23v-96H678l20.4 27.4c6.2 8.2 20.8 28.9 21 28.9h.1c.1 0-.1-17-.1-34.4v-21.9l23-.1z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M11.4 245.6v-96h11.2v96H11.4zM130 149.6v96h-11.1v-43.2c0-14.8.7-33.9.5-33.9h-.1c-.1 0-4.9 8.8-8.4 14.5l-22.4 39h-5.9l-22.4-39c-3.4-5.8-8.2-14.5-8.4-14.5h-.1c-.1 0 .5 19.1.5 33.9v43.2H41.3v-96h10.6l23.9 40.6c8.2 14 9.7 17.8 9.9 17.8h.1c.1 0 1.6-3.8 9.9-17.8l23.9-40.6H130zM249.8 160l-56.2 67.3c-4 4.7-7.1 7.8-7.1 8l.1.1c.1 0 5.3-.4 12.3-.4h50.9v10.6h-78.2v-10.4l57.1-67.8c2.7-3.3 7.3-7.3 7.3-7.4l-.1-.1c-.1 0-5.3.3-12.5.3H173v-10.6h76.8V160zM328.8 235v10.6h-64.9v-96H328v10.6h-52.8v29.6h42.4v10h-42.4V235h53.6zM422 149.6v96h-10.6l-42.7-57.5c-8.2-11.4-14.3-20.2-14.4-20.2h-.1c-.1 0 .3 11.9.3 27.3v50.3h-11.2v-96h10.6l42.7 57.3c7.3 9.7 14.3 19.8 14.4 19.8h.1c.1 0-.3-12.9-.3-26.6v-50.5l11.2.1zM506.4 149.6v10.6h-32.2v85.4h-11.1v-85.4H431v-10.6h75.4zM571.1 245.6l-21.7-35.9c-1.5.1-3.3.1-4.9.1h-18v35.8h-11.2v-96h29.2c22.5 0 35.9 11.8 35.9 30.3 0 13.7-7.3 23.5-20 27.6l23.3 38.1h-12.6zm-25.5-45.8c15.4 0 23.3-8.4 23.3-19.9 0-12.2-8.6-19.8-23.3-19.8h-19.1v39.6l19.1.1zM672.1 149.6v56.2c0 27.2-14.4 41.8-39.5 41.8s-39.4-14.7-39.4-41.8v-56.2h11.2V206c0 20.8 9.6 31.1 28.1 31.1 18.7 0 28.3-10.3 28.3-31.1v-56.4h11.3zM776.9 149.6v96h-11.1v-43.2c0-14.8.7-33.9.5-33.9h-.1c-.1 0-4.9 8.8-8.4 14.5l-22.4 39h-5.9l-22.4-39c-3.4-5.8-8.2-14.5-8.4-14.5h-.1c-.1 0 .5 19.1.5 33.9v43.2H688v-96h10.6l23.9 40.6c8.2 14 9.7 17.8 9.9 17.8h.1c.1 0 1.6-3.8 9.9-17.8l23.9-40.6h10.6z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M136 10.6v125.1h-29.5v-30.6c0-29.7 1.1-44.3.9-44.3h-.2c-.2.1-10.2 17.2-11.3 19l-14.7 25H65.7L51 79.8C50 78 40 60.9 39.8 60.9h-.2c-.2 0 .9 14.7.9 44.3v30.6H11V10.6h28.2l23.4 37.9c9.1 14.8 10.5 18.1 10.7 18.1h.2c.2 0 1.6-3.2 10.7-18.1l23.4-37.9H136zM255.9 107.7v28.1h-92.1V10.6h91v28.1h-61v20.6h47.7v26.3h-47.7v22.2l62.1-.1zM337.1 10.6c35.8 0 64.7 25.6 64.7 63.1s-29 62-64.7 62h-54.7V10.6h54.7zm0 97.1c17.9 0 33.4-12.5 33.4-34s-15.6-35-33.4-35h-24.7v69h24.7zM422 135.8V10.6h30v125.1h-30v.1zM582.5 38.7l-53.3 57.4c-5.5 5.9-11.6 11.8-11.6 12l.2.2c.2 0 8.8-.5 19.5-.5h45.2v28.1H476.7v-28.1l55.8-58.6c4.5-4.8 11.6-10.5 11.6-10.7l-.2-.2c-.2 0-11.6.5-19.5.5h-45.9V10.6h104v28.1zM607.2 135.8V10.6h30v125.1h-30v.1zM777.4 10.6v125.1h-28.2l-25.6-34.9c-11.4-15.7-28.2-39.5-28.4-39.5h-.2c-.2 0 .2 19.3.2 46.7v27.7h-30V10.6h28.2L720 46.4c8 10.7 27.2 37.7 27.3 37.7h.2c.2 0-.2-22.2-.2-44.9V10.6h30.1z"
        />
      </g>
      <g>
        <path
          d="M718.1 280.4s25.3-4.6 55.1 0v47.8c0 9.2-26 18.4-27.6 18.4-1.5 0-27.6-9.2-27.6-18.4.1-9.3.1-47.8.1-47.8z"
          fill="#0076be"
        />
        <path
          className="prefix__st1"
          d="M745.7 348.5c-1.5 0-8.9-2.7-15.3-6-9.5-4.9-14.3-9.7-14.3-14.4v-49.4l1.7-.3c.3 0 6.6-1.2 16.6-1.8 9.3-.5 23.6-.7 39.2 1.7l1.7.3v49.5c0 4.7-4.8 9.6-14.3 14.4-6.4 3.4-13.8 6-15.3 6zm-25.5-66.4v46c0 2.9 4.2 6.7 11.7 10.6 6.3 3.3 12.5 5.4 13.9 5.7 1.3-.3 7.6-2.4 13.9-5.7 7.4-3.9 11.7-7.7 11.7-10.6v-46c-24.3-3.4-45.3-.9-51.2 0z"
        />
        <g>
          <path
            className="prefix__st1"
            d="M727.2 287.4c1.4-.1 16.9-2 36.5 0 1.4.1 1.4 1.4 1.4 1.4v2.3c0 1.4-1.4 1.4-1.4 1.4h-30.8c-1.4 0-1.4 1.4-1.4 1.4v2.8c0 1.4 1.4 1.4 1.4 1.4h30.8c1.4 0 1.4 1.4 1.4 1.4v27.1c0 1.2-2.2 2.6-2.6 2.9-.5.3-3 2-3 2v-1.8-13.4s0-1.4-1.4-1.4h-7.8c-1.4 0-1.4 1.4-1.4 1.4v20.3l-1.7.9s-1.1.6-1.4.6c-.3 0-1.5-.7-1.5-.7l-1.6-.8.1-20.3s0-1.4-1.4-1.4H733c-1.4 0-1.4 1.4-1.4 1.4v15.1s-5.6-3.5-5.6-4.9v-15.8s0-1.4 1.4-1.4h30.8s1.4 0 1.4-1.4v-2.8s0-1.4-1.4-1.4h-30.8c-1.3 0-1.4-1.4-1.4-1.4v-13.5c-.2 0-.1-1.3 1.2-1.4z"
          />
          <path
            d="M745.7 338.4c-.3 0-1.2-.4-1.7-.7l-1.8-.9.1-20.5c0-.2-.1-1.1-1.1-1.1h-8.4c-.4 0-.8.2-.9.5-.1.3-.1.5-.1.6V332l-.5-.3c-1-.6-5.8-3.7-5.8-5.2v-15.8c0-.6.4-1.7 1.7-1.7H758c.2 0 1.1-.1 1.1-1.1v-2.8c0-.2-.1-1.1-1.1-1.1h-30.8c-1.3 0-1.7-1.1-1.7-1.7v-13.5c.1-.5.5-1.6 1.7-1.7.1 0 .3 0 .6-.1 14.8-1.4 27.7-.8 35.9.1 1.3.1 1.7 1.2 1.7 1.7v2.3c0 1.4-1.1 1.7-1.7 1.7h-30.8c-1 0-1.1 1-1.1 1.1v2.8c0 1 .9 1.1 1.1 1.1h30.8c.9 0 1.3.5 1.5.9.2.4.2.8.2.9v27.1c0 1.2-1.5 2.3-2.6 3.1l-.1.1c-.5.3-2.9 2-3 2l-.5.3v-15.8c0-.2-.1-1.1-1.1-1.1h-7.8c-1 0-1.1 1-1.1 1.1v20.5l-1.9.9c-.4.1-1.2.6-1.6.6zm-2.8-2l1.4.7c.5.3 1.2.6 1.4.6.1 0 .8-.3 1.3-.6l1.5-.8v-20.1c0-.6.4-1.7 1.7-1.7h7.8c1.4 0 1.7 1.1 1.7 1.7v14.5c.8-.5 2.2-1.4 2.5-1.7l.1-.1c1.9-1.4 2.3-2.1 2.3-2.6v-27.1c0-.2-.1-1.1-1.1-1.1h-30.8s-.4 0-.9-.2c-.4-.2-.9-.6-.9-1.5v-2.8s0-.4.2-.9c.2-.4.6-.9 1.5-.9h30.8c.2 0 1.1-.1 1.1-1.1v-2.3c0-.1 0-1-1.1-1.1-8.2-.8-21-1.5-35.7-.1-.3 0-.5.1-.7.1-.9.1-1.1.9-1.1 1.1v13.5c0 .1.1 1.1 1.1 1.1h31c1.4 0 1.7 1.1 1.7 1.7v2.8c0 .9-.5 1.3-.9 1.5-.4.2-.8.2-.9.2h-30.8c-1 0-1.1.9-1.1 1.1v15.8c0 .7 2.4 2.7 4.9 4.3v-14.6s0-.4.2-.9c.2-.4.6-.9 1.5-.9h8.4c1.4 0 1.7 1.1 1.7 1.7l.2 20.7z"
            fill="#fffdfd"
          />
        </g>
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M380.9 305.9c-.9-3.2-3.6-4.7-6.2-4.7s-4.1 1.3-4.1 2.7c0 2.4 3.6 3.2 8 4.8 5.3 1.9 10.9 5 10.9 11.1 0 6.6-5.6 11.2-14 11.2-7.5 0-12.8-4.2-15.2-10.9l8.3-2.2c.9 2.7 3.5 5.4 7.3 5.4 3.1 0 4.5-1.4 4.5-3.2 0-2.9-4.2-3.6-9.3-5.5-4.3-1.6-9.3-4-9.3-10.3 0-5.6 5.6-10.7 13.4-10.7 6.9 0 12.3 4.2 14 9.9l-8.3 2.4zM408.5 294.5c8.8 0 14.2 4.6 14.2 12.3 0 7.7-5.3 12.3-14.2 12.3h-5.1v11.3h-8.6v-35.9h13.7zm-.2 16.5c3.3 0 5.3-1.6 5.3-4.3 0-2.7-2-4.3-5.3-4.3h-4.9v8.5h4.9zM444.6 293.7c10.3 0 18.7 7.8 18.7 18.7 0 10.8-8.4 18.7-18.7 18.7-10.3 0-18.6-7.8-18.6-18.7 0-10.8 8.3-18.7 18.6-18.7zm0 28.9c5.8 0 9.7-4.2 9.7-10.2 0-6.1-3.8-10.2-9.7-10.2-5.8 0-9.6 4.1-9.6 10.2-.1 6 3.8 10.2 9.6 10.2zM488.3 330.4l-6.7-11.7h-4.4v11.7h-8.6v-35.9H482c9 0 14.5 4.9 14.5 12.3 0 4.8-2.3 8.4-6.5 10.4l8 13.2h-9.7zm-11-19.4h6c3.1 0 4.3-2.3 4.3-4.3 0-1.9-1.2-4.3-4.3-4.3h-6v8.6zM530.4 294.5v8.1h-10.9v27.9h-8.6v-27.9H500v-8.1h30.4zM568.8 294.5v35.9h-4.2v-16.2c0-5.5.3-12.7.2-12.7h-.1c-.1 0-1.8 3.3-3.1 5.4l-8.4 14.6H551l-8.4-14.6c-1.3-2.2-3.1-5.4-3.1-5.4h-.1c-.1 0 .2 7.1.2 12.7v16.2h-4.2v-35.9h4l8.9 15.2c3.1 5.2 3.6 6.7 3.7 6.7h.1c.1 0 .6-1.4 3.7-6.7l8.9-15.2h4.1zM602.6 326.4v4h-24.3v-35.9h24v4h-19.8v11.1h15.9v3.7h-15.9v13.2h20.1zM625.8 294.5c10.3 0 18.6 7.4 18.6 18.2 0 10.6-8.2 17.8-18.3 17.8h-15.4v-35.9h15.1zm.3 31.9c7.5 0 14-5.2 14-13.8 0-8.7-6.5-14.2-14.3-14.2h-10.9v28h11.2zM650.9 330.4v-35.9h4.2v35.9h-4.2zM692.5 304.3c-2.5-4-7-6.4-12.1-6.4-8.2 0-14.3 5.9-14.3 14.6s6.3 14.5 14.3 14.5c5.5 0 10.3-2.9 12.7-7.4l4.2 1.1c-3 6.4-9.3 10.5-16.8 10.5-10.3 0-18.6-7.8-18.6-18.7 0-10.8 8.3-18.7 18.6-18.7 7 0 13.1 3.7 16.3 9.4l-4.3 1.1z"
        />
      </g>
      <g>
        <path
          className="prefix__st1"
          d="M20.2 294.9c8.5 0 13.7 4.4 13.7 11.8 0 7.3-5.2 11.8-13.7 11.8h-7v13.3H8.9v-36.9h11.3zm-.1 19.5c6.1 0 9.4-3 9.4-7.8 0-5-3.6-7.7-9.4-7.7h-6.9v15.4h6.9zM57.5 294.1c10.6 0 19.2 8.1 19.2 19.2s-8.6 19.2-19.2 19.2-19.1-8.1-19.1-19.2c-.1-11.1 8.5-19.2 19.1-19.2zm0 34.2c8.3 0 14.7-6.1 14.7-15 0-9-6.4-15-14.7-15-8.4 0-14.7 6-14.7 15 0 8.9 6.3 15 14.7 15zM127.1 294.9l-12.5 36.9h-3.8l-6.1-19.4c-1.6-5.2-2-7.2-2.1-7.2h-.1c-.1 0-.4 2.1-2 7.2l-6.1 19.4h-3.8l-12.5-36.9h4.5l8.3 24.9c1 3.2 1.5 5.4 1.6 5.4h.1c.1 0 .5-2.2 1.6-5.4l6.5-20h3.8l6.5 20c1.1 3.2 1.5 5.4 1.6 5.4h.1c.1 0 .6-2.2 1.6-5.4l8.3-24.9h4.5zM157.5 327.7v4.1h-24.9v-36.9h24.6v4.1h-20.3v11.4h16.3v3.8h-16.3v13.5h20.6zM187.2 331.8l-8.3-13.8c-.6.1-1.3.1-1.9.1h-6.9v13.8h-4.3V295H177c8.6 0 13.8 4.5 13.8 11.7 0 5.3-2.8 9-7.7 10.6l9 14.7h-4.9zm-9.8-17.6c5.9 0 9-3.2 9-7.6 0-4.7-3.3-7.6-9-7.6h-7.3v15.2h7.3zM224.3 327.7v4.1h-24.9v-36.9H224v4.1h-20.3v11.4H220v3.8h-16.3v13.5h20.6zM248 294.9c10.5 0 19.1 7.6 19.1 18.7 0 10.9-8.4 18.2-18.8 18.2h-15.9v-36.9H248zm.3 32.8c7.7 0 14.3-5.4 14.3-14.2 0-9-6.6-14.6-14.7-14.6h-11.2v28.8h11.6zM301.4 294.9c6.3 0 10.6 3.7 10.6 9.1 0 3.7-3.2 7-6.1 7.5v.1c3.6.5 8.5 4.1 8.5 9.4 0 6.4-4.4 10.9-12.2 10.9h-14.7V295h13.9zm-.9 15.3c4.3 0 7.2-2.7 7.2-5.9 0-3.5-2.9-5.7-7.2-5.7h-8.7v11.6h8.7zM302 328c4.5 0 8-2.7 8-7.2 0-4.2-3.7-7.2-8.2-7.2h-9.9V328H302zM347.6 294.9l-14.3 18.8v18.1H329v-18.1l-14.3-18.8h4.9l9.3 12.1c.9 1.2 2.2 2.9 2.3 2.9h.1c.1 0 1.3-1.7 2.2-2.9l9.2-12.1h4.9z"
        />
      </g>
    </svg>
  )
}

export default MedizinLogo