import React from 'react';
import { MapContainer } from './google-map';
import { Link } from 'gatsby';
import dataPersons, { dataJulia } from '../hooks/dataPersons';
import MedizinLogo from './medizin-logo';
import LogoSportmedic from './logo-sportmedic';

function Footer() {
	return (
		<footer id="kontakt" className="bg-gray-700 text-white">
			<section className="flex flex-wrap p-4 mx-auto max-w-4x1 bg-gray-600">
				<div className="w-full md:w-1/3 text-right p-2">
					<div className="w-full sm:w-1/2 md:w-full">
						<h1 className="font-medium text-lg">
							<span className="logo-text">movendos</span> @{' '}
							<a href={'https://sportmedic.at/'}>Sportmedic</a>{' '}
						</h1>
						<p>
							Schreyvogelgasse 3/5 <br /> 1010 Wien{' '}
						</p>
					</div>
					{dataPersons.map((person, key) => {
						let book = <span></span>
						if(person.bookURL !== undefined) {
							book = <div><a href={person.bookURL}>Online-Terminvereinbarung</a></div>
						}
						return (
						<div key={key} className="flex md:justify-end my-2 w-full sm:w-1/2 md:w-full">
							<div className="bg-gray-800 rounded-lg p-1 px-3 w-56">
								<h2 className="font-medium"><Link to={person.link} alt={person.name}>{person.name}</Link></h2>
								<a href={'mail'.concat('to:', person.email.props.children)}>{person.email}</a>
								<br /> <a href={`tel:${person.phone}`}>{person.phone}</a>
								{book}
							</div>
						</div>
					)})}
				</div>
				<div id="map" className="w-full md:w-2/3 h-1/2 p-4">
					<MapContainer />
				</div>
			</section>
			<nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
				<p className="text-white">
					Design: {` `}
					<a className="no-underline" href="https://palmesi.com" target="_blank" rel="noopener noreferrer">
						Pietro Palmesi
					</a>{' '}
					&amp; {dataJulia.name}
				</p>

				<p>
					<Link className="font-medium text-white no-underline" to="/impressum">
						Impressum
					</Link>
				</p>
			</nav>
			<nav className="flex justify-between max-w-4xl px-4 mx-auto text-sm md:px-8">
				Partner:
			</nav>
			<nav className="flex flex-wrap justify-between max-w-4xl pt-1 p-4 mx-auto text-sm md:pt-1 md:p-8">
				<p/>
				<p className="text-white">
					<a href="https://medizin-zentrum.at/">
						<MedizinLogo className="h-10" />
					</a>
				</p>

				<p className="text-white">
					<a href="https://sportmedic.at/">
						<LogoSportmedic className="h-10" />
					</a>
				</p>
				<p/>
			</nav>
		</footer>
	);
}

export default Footer;
