import React from 'react';

export const dataChristian = {
	link: 'christian',
	firstName: 'Christian',
	lastName: 'Slechta-Pleterski',
	name: 'Christian Slechta-Pleterski',
	fullName: 'Christian Slechta-Pleterski, Bsc',
	email: (
		<span>
			&#99;&#104;&#114;&#105;&#115;&#116;&#105;&#97;&#110;&#64;&#109;&#111;&#118;&#101;&#110;&#100;&#111;&#115;&#46;&#97;&#116;
		</span>
	),
	phone: '+43 676 663 71 27',
	bookURL: 'https://www.appointmed.com/booking/243291/user/243293'
};
export const dataJulia = {
	link: 'julia',
	firstName: 'Julia',
	lastName: 'Slechta-Pleterski',
	name: 'Julia Slechta-Pleterski',
	fullName: 'Julia Slechta-Pleterski, Bsc',
	karenz: 'derzeit in Karenz',
	email: (
		<span>&#106;&#117;&#108;&#105;&#97;&#64;&#109;&#111;&#118;&#101;&#110;&#100;&#111;&#115;&#46;&#97;&#116;</span>
	),
	phone: '+43 650 792 57 37',
	// bookURL: 'https://www.appointmed.com/booking/243291-christian-slechta-pleterski/user/582779-julia-slechta-pleterski'
};

const dataPersons = [ dataJulia, dataChristian ];
export default dataPersons;
