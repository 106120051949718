import PropTypes from 'prop-types';
import React from 'react';

import Header from './header';
import Navbar from './navbar';
import Footer from './footer';

class Layout extends React.Component {
	render() {
		return (
			<div className="flex flex-col min-h-screen font-sans text-gray-900">
				<Header showHero={this.props.showHero} />

				<Navbar />
				<div id="content" className="bg-white w-full">
					<main className="flex-1 w-full max-w-4xl p-4 mx-auto md:p-8 mt-4">{this.props.children}</main>
				</div>
				<Footer />
			</div>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	showHero: PropTypes.bool
};

Layout.defaultProps = {
	showHero: false
};
export default Layout;
